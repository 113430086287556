import { Button } from 'src/components/ui/button'
import { IAuction, IAuctionSupplier } from 'src/_models/auction.model'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useUserStore } from 'src/_store/user.store'
import { differenceInDays } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import * as React from 'react'
import { FUEL_FEES_CALC, BUYER_FEES_CALC, TAXES_CALC } from 'src/lib/constants'
import { toast, useToast } from 'src/components/ui/use-toast'
import { cn } from 'src/lib/utils'
import AuctionDialogHeader from 'src/components/auction/auction-dialog-header'
import AuctionDialogEstimatedShippingAndShippingRadius from 'src/components/auction/auction-dialog-estimated-shipping-and-shipping-radius'
import { isPastAuction } from 'src/lib/auction.util'
import AuctionDialogFeesCalculation from 'src/components/auction/auction-dialog-fees-calculation'
import AuctionDialogTopHeader from 'src/components/auction/auction-dialog-top-header'

const SupplierAuctionDialogPurchaseNow = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const [total, setTotal] = useState(0)
  const { t } = useTranslation('translation')
  const [confirm, setConfirm] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const [auctionData, setAuctionData] = useState<IAuctionSupplier | null>(null)
  const {
    isLoading,
    data: responseData,
    error,
  } = useQuery({
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuctionSupplier }>(`/api/supplier/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (responseData && responseData.data) {
      setAuctionData(responseData.data.auction)
    }
  }, [responseData])

  const cancelAuctionMutation = useMutation({
    mutationFn: (): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/auctions/${auction.id}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
    onSuccess: (resp) => {
      toast({
        title: t(resp.data.message),
      })
      setShowDialog(false)
      queryClient.invalidateQueries({
        queryKey: ['supplier-my-auctions'],
      })
    },
    onError: (err) => {
      const error = err as AxiosError<{ message: string }>
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: t(error.response?.data?.message || 'error.somethingWrong'),
      })
    },
  })

  if (isLoading || error || !auctionData) {
    return
  }

  const subTotal = auction?.sellingPricePerUnit * auction.fuelWeight

  const confirmCancelAction = () => {
    cancelAuctionMutation.mutate(undefined, {
      onSuccess: () => {
        toast({
          title: t('Cancelled auction.'),
        })
        setShowDialog(false)
      },
      onError: (e) => {
        const error = e as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message || 'error.somethingWrong'),
        })
      },
    })
  }

  return (
    <>
      <div className="mx-auto h-full w-full max-w-4xl overflow-y-auto rounded-[40px] bg-white px-[30px] py-[30px]">
        <div className="text-2xl font-bold text-secoundary">
          <AuctionDialogHeader auction={auctionData} />
          <hr className="my-5 h-px border-0 bg-[#E0E0E0]" />
        </div>
        <div className="max-h-[calc(100vh_-_380px)] overflow-auto pr-1">
          <AuctionDialogTopHeader auction={auctionData} />
          <div className="my-5 flex justify-between gap-5">
            <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[17px]">
              <div className="text-sm font-normal 2xl:text-base">Quantity</div>
              <div className="text-lg font-bold 2xl:text-xl">
                {auctionData.fuelWeight} {t('fuelWeightUnit.' + auctionData.fuelWeightUnit)}
              </div>
            </div>

            <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[17px]">
              <div className="text-sm font-normal 2xl:text-base">Buying Price</div>
              <div className="text-lg font-bold 2xl:text-xl">
                <CurrencyFormatter currency={auction.sellingCurrency} amount={subTotal} cents={true} />
              </div>
            </div>
          </div>

          <div className="mt-5 flex w-full flex-col rounded-lg">
            <div className="flex flex-col px-[30px]">
              <AuctionDialogFeesCalculation auction={auction} bidAmount={subTotal} setTotal={setTotal} cents={true} />
              <AuctionDialogEstimatedShippingAndShippingRadius auction={auction} />
            </div>
          </div>
        </div>
        <hr className="mt-[10px] h-px border-0 bg-[#E0E0E0]" />

        <div className="flex w-full flex-col rounded-lg py-5">
          <div className="flex flex-col px-[30px]">
            <div className="flex w-full items-center justify-between gap-[10px]">
              <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
                Total Payable Amount
              </p>
              <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
                <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={true} />
              </p>
            </div>
          </div>
        </div>
        {/* Only show the buttons if the auction is not past auction */}
        {!isPastAuction(auctionData) && (
          <>
            {confirm && (
              <div className="mt-0px px-[30px] pb-5 text-sm font-normal 2xl:text-base">
                *Are you sure, you want to Cancel Your Bid for{' '}
                <span className="uppercase">
                  {auctionData.fuelColor.name} {auctionData.fuelType.name}
                </span>{' '}
                {auctionData.fuelPurityLevel.purity} amount of
                <span className="text-base font-bold 2xl:text-lg">
                  <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={true} />
                </span>
              </div>
            )}
            <div className="flex gap-5">
              {!confirm && (
                <Button onClick={() => setConfirm(true)} tabindex="-1" variant="secondary">
                  Cancel
                </Button>
              )}
              {confirm && <Button onClick={confirmCancelAction}>Confirm Cancel</Button>}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SupplierAuctionDialogPurchaseNow
