import { cn } from 'src/lib/utils'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import BuyerAuctionUpdateBidDialog from 'src/components/auction/buyer-auction-update-bid-dialog'
import BuyerAuctionPlaceBidDialog from 'src/components/auction/buyer-auction-place-bid-dialog'
import * as React from 'react'
import { useState } from 'react'
import { useUserStore } from 'src/_store/user.store'
import { useTranslation } from 'react-i18next'
import { IAuction } from 'src/_models/auction.model'
import AuctionCardTopStrip from 'src/components/auction/auction-card-top-strip'
import AuctionCardTopHeading from 'src/components/auction/auction-card-top-heading'
import CurrencyFormatter from 'src/components/ui/currency-formatter'

type IAuctionCardProps = {
  auction: IAuction
  className?: string
}

const AuctionCardBuyer = ({ auction, className }: IAuctionCardProps) => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const getHighestBid = () => {
    if (!auction.bids) {
      return '-'
    }
    if (auction.bids.length === 0) {
      return '-'
    }

    let highest = 0
    for (const bid of auction.bids) {
      if (highest < bid.bidAmount) highest = bid.bidAmount
    }
    return <CurrencyFormatter currency={auction.sellingCurrency} amount={highest} cents={true} />
  }

  const getHighestBidUserId = () => {
    if (!auction.bids) {
      return
    }
    if (auction.bids.length === 0) {
      return '-'
    }

    let highest = 0
    let userId
    for (const bid of auction.bids) {
      if (highest < bid.bidAmount) {
        highest = bid.bidAmount
        userId = bid.userId
      }
    }
    return userId
  }

  const buyerBidExists = user?.userType === 'BUYER' && auction.bids.some((bid) => bid.userId === user.id)
  const highestBidCurrentUser = user?.userType === 'BUYER' && getHighestBidUserId() === user.id

  const formatPrice = () => {
    const price = auction.sellingPricePerUnit * auction.fuelWeight
    return <CurrencyFormatter currency={auction.sellingCurrency} amount={price} cents={true} />
  }

  if (highestBidCurrentUser) {
    return (
      <div
        className={cn(
          'shadow-[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] relative mb-4 h-[221px] w-[305px] rounded-[20px] border-[5px] border-white bg-white 2xl:h-[270px] 2xl:w-[336px]',
          className,
          'bg-primary',
        )}
      >
        <AuctionCardTopStrip auction={auction} highestBidCurrentUser={highestBidCurrentUser} />
        <div className="flex w-full flex-col px-[10px] py-[10px] font-montserrat 2xl:px-[15px]  2xl:py-[15px]">
          <AuctionCardTopHeading auction={auction} highestBidCurrentUser={highestBidCurrentUser} />
          <div className="flex w-full items-center justify-between gap-[6px]">
            <div className="flex flex-col pr-3 align-middle text-secoundary">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-2">
                  <div className="text-sm font-bold leading-[17px] text-white 2xl:text-base 2xl:leading-5 uppercase">
                    {auction.fuelColor.name} {auction.fuelType.name}
                  </div>
                  <div className="rounded bg-black px-[3px] text-sm font-bold uppercase leading-[17px] text-white 2xl:text-base 2xl:leading-[19.5px]">
                    {t('fuelStateShorthand.' + auction.fuelState)}
                  </div>
                </div>
                <div className="flex gap-[5px] align-middle">
                  <div className="rounded bg-gray2 px-[3px] text-sm font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
                    {auction.fuelPurityLevel?.purity}
                  </div>
                  <div className="rounded bg-[#F2994A] px-[3px] text-sm font-bold uppercase leading-[17px] text-secoundary 2xl:text-base 2xl:leading-[19.5px]">
                    {t('tradeType.' + auction.tradeType)}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-fit rounded-[12px] bg-gray2 bg-opacity-10">
              <div className="flex w-full flex-col items-center gap-[5px] p-[10px] align-middle ">
                <span className="text-[10px] font-normal leading-3 text-white 2xl:text-sm 2xl:leading-[14.63px]">
                  QTY ({t('fuelWeightUnit.' + auction.fuelWeightUnit)})
                </span>
                <span className="text-sm font-bold leading-[17px] text-white 2xl:text-base 2xl:leading-[19.5px]">
                  {auction.fuelWeight}
                </span>
              </div>
            </div>
          </div>
          <div className="my-[5px] flex w-full items-center divide-x divide-verticalline align-middle 2xl:py-[3px]">
            <div className="flex w-full flex-col px-2  text-center">
              <span className="text-center text-[12px] font-normal uppercase leading-[17px] text-white 2xl:pb-1 2xl:text-sm">
                Starting Price
              </span>
              <span className="text-center text-sm font-bold leading-[17px] text-white 2xl:text-base 2xl:leading-5">
                {formatPrice()}
              </span>
            </div>
            <div className="flex w-full flex-col items-center text-secoundary">
              <span className="px-2 text-center text-[12px] uppercase text-white 2xl:pb-1 2xl:text-sm">
                Highest BID
              </span>
              <span className="text-center text-sm font-bold text-white 2xl:text-base">{getHighestBid()}</span>
            </div>
          </div>

          <div className="mt-[10px] 2xl:mt-[13px]">
            <Dialog open={showDialog} onOpenChange={(e) => setShowDialog(e)}>
              <DialogTrigger asChild>
                <Button variant="secondary">Highest Bid</Button>
              </DialogTrigger>
              <DialogContent className="max-h-5/6 h-auto w-full max-w-[723px] overflow-y-auto rounded-[40px] p-0">
                {buyerBidExists ? (
                  <BuyerAuctionUpdateBidDialog auction={auction} setShowDialog={setShowDialog} />
                ) : (
                  <BuyerAuctionPlaceBidDialog auction={auction} setShowDialog={setShowDialog} />
                )}
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'shadow-[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] relative  mb-4 h-[221px] w-[305px] rounded-[20px] bg-white 2xl:h-[270px] 2xl:w-[336px]',
        className,
      )}
    >
      <AuctionCardTopStrip auction={auction} />
      <div className="flex w-full flex-col px-[10px] py-[10px] font-montserrat 2xl:px-[15px]  2xl:py-[15px]">
        <AuctionCardTopHeading auction={auction} />
        <div className="flex w-full items-center justify-between gap-[6px]">
          <div className="flex flex-col pr-3 align-middle text-secoundary">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row gap-2">
                <div className="text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-5 uppercase">
                  {auction.fuelColor.name} {auction.fuelType.name}
                </div>
                <div className="rounded bg-black px-[3px] text-sm font-bold uppercase leading-[17px] text-white 2xl:text-base 2xl:leading-[19.5px]">
                  {t('fuelStateShorthand.' + auction.fuelState)}
                </div>
              </div>
              <div className="flex gap-[5px] align-middle">
                <div className="rounded bg-gray2 px-[3px] text-sm font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
                  {auction.fuelPurityLevel?.purity}
                </div>
                <div className="rounded bg-[#F2994A] px-[3px] text-sm font-bold uppercase leading-[17px] text-secoundary 2xl:text-base 2xl:leading-[19.5px]">
                  SPOT
                </div>
              </div>
            </div>
          </div>
          <div className="w-fit rounded-[12px] bg-gray2">
            <div className="flex w-full flex-col items-center gap-[5px] p-[10px] align-middle ">
              <span className="text-[10px] font-normal leading-3 text-secoundary 2xl:text-sm 2xl:leading-[15px]">
                QTY ({t('fuelWeightUnit.' + auction.fuelWeightUnit)})
              </span>
              <span className="text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-[20px]">
                {auction.fuelWeight}
              </span>
            </div>
          </div>
        </div>
        {auction.auctionType === 'PURCHASE_NOW' ? (
          <div className="mt-[10px] flex h-[50px] w-full items-center align-middle 2xl:mt-[10px] 2xl:h-[55px]">
            <div className="flex w-full items-center justify-between px-2 py-[5px]">
              <span className="text-center text-xs font-normal uppercase 2xl:text-sm">Buying Price</span>
              <span className="text-center text-sm font-bold 2xl:text-base">{formatPrice()}</span>
            </div>
          </div>
        ) : (
          <div className="my-[5px] flex w-full items-center divide-x divide-verticalline py-[5px] align-middle 2xl:mt-[10px]">
            <div className="flex w-full flex-col px-2  text-center ">
              <span className="pb-2 text-center text-[12px] font-normal uppercase leading-[15px] 2xl:text-sm 2xl:leading-[17px]">
                Starting Price
              </span>
              <span className="text-center text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-[19px]">
                {formatPrice()}
              </span>
            </div>
            <div className="flex w-full flex-col items-center  text-secoundary">
              <span className="px-2 pb-2 text-center text-[12px] uppercase leading-[15px] 2xl:text-sm 2xl:leading-[17px]">
                Highest BID
              </span>
              <span className="text-center text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-[19px]">
                {getHighestBid()}
              </span>
            </div>
          </div>
        )}
        <div className="mt-[5px] 2xl:mt-[13px]">
          <Dialog open={showDialog} onOpenChange={(e) => setShowDialog(e)}>
            {auction.auctionType === 'PURCHASE_NOW' ? (
              <DialogTrigger asChild>
                <Button>Buy Now</Button>
              </DialogTrigger>
            ) : (
              <>
                {buyerBidExists ? (
                  <DialogTrigger asChild>
                    <Button>Update Bid</Button>
                  </DialogTrigger>
                ) : (
                  <DialogTrigger asChild>
                    <Button>Place Bid</Button>
                  </DialogTrigger>
                )}
              </>
            )}
            <DialogContent className="max-h-5/6 h-auto w-full max-w-[723px] overflow-y-auto rounded-[40px] p-0">
              {buyerBidExists ? (
                <BuyerAuctionUpdateBidDialog auction={auction} setShowDialog={setShowDialog} />
              ) : (
                <BuyerAuctionPlaceBidDialog auction={auction} setShowDialog={setShowDialog} />
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default AuctionCardBuyer
