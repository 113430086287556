import React, { useState, MouseEvent } from 'react'
import { WallyMessaging } from '../../types/types'
import axios from 'axios'
import { useUserStore } from 'src/_store/user.store'
import { XIcon, ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from 'src/lib/utils'

// interface ChatBoxWallyProps {
//   messages: WallyMessaging[]
// }

const initialMessages: WallyMessaging[] = [
  {
    timestamp: '10:00 AM',
    user_whosend: 'Wally',
    message: 'Hi, I am Wally, ask me anything',
  },
  // more messages...
]

export const ChatBoxWally: React.FC<{ toogleChat: (e: MouseEvent<HTMLSpanElement>) => void }> = ({ toogleChat }) => {
  const { user } = useUserStore((state) => ({ user: state.user }))

  const [messages, setMessages] = useState<WallyMessaging[]>(initialMessages)
  const [minimised, setMinimised] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value)
  }

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!newMessage.trim()) return

    const user_name =
      (user?.firstName ? user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) : '') +
      ' ' +
      (user?.lastName ? user.lastName.charAt(0).toUpperCase() : '')

    const userMessage: WallyMessaging = {
      timestamp: new Date().toLocaleTimeString(),
      user_whosend: user_name,
      message: newMessage,
    }

    setMessages((prevMessages) => [...prevMessages, userMessage])
    setNewMessage('')

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        '/api/chatbot',
        { messages: [...messages, userMessage] },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.status !== 200) {
        throw new Error('Network response was not ok')
      }

      const data = await response.data
      const wallyMessage: WallyMessaging = {
        timestamp: new Date().toLocaleTimeString(),
        user_whosend: 'Wally',
        message: data,
      }

      setMessages((prevMessages) => [...prevMessages, wallyMessage])
    } catch (error) {
      console.error('Error sending message:', error)
    }
    console.log('Send message:', newMessage)
    setNewMessage('')
  }

  return (
    <>
      {minimised ? (
        <div
          onClick={() => setMinimised(!minimised)}
          className="w-52 flex items-center justify-between border-[3px] border-primary border-b-0 line-height relative cursor-pointer rounded-t-lg bg-darkblue px-5 py-2 text-sm font-bold text-white 2xl:py-3 2xl:text-base"
        >
          Wally AI
          <span className="cursor-pointer p-1 pr-0" onClick={toogleChat}>
            <XIcon className="h-4" />
          </span>
        </div>
      ) : (
        <div
          style={{ boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);' }}
          className="bg-darkblue rounded-[20px] border-primary w-[440px] border-[3.1px] h-[634px] overflow-hidden p-4"
        >
          <div className="flex flex-row justify-between items-center pb-6 text-white">
            <h2 className="font-bold text-xl tracking-tight">Wally AI</h2>
            <span className="flex flex-row gap-2">
              <span className="p-2 cursor-pointer" onClick={() => setMinimised(!minimised)}>
                <ChevronDown />
              </span>
              <span className="p-2 cursor-pointer" onClick={toogleChat}>
                <XIcon />
              </span>
            </span>
          </div>

          <>
            <div className="h-[474px] min-w-full overflow-y-auto hide-scroll">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex w-full mt-4 space-x-3 max-w-${
                    msg.user_whosend === 'Wally' ? 'xs' : 'sm ml-auto justify-end'
                  } items-center`}
                >
                  {msg.user_whosend === 'Wally' && (
                    <span className="relative flex gap-2 self-start pt-3">
                      <div className="avtar text-base font-bold text-lightblue">{msg.user_whosend}</div>
                    </span>
                  )}
                  <div className="mt-0">
                    <div
                      className={`p-3 rounded-${
                        msg.user_whosend === 'Wally'
                          ? 'r-lg rounded-b-lg bg-[#0177b5]'
                          : 'l-lg rounded-br-lg bg-lightblue'
                      }`}
                    >
                      <p className={`text-sm ${msg.user_whosend === 'Wally' ? 'text-white' : 'text-darkblue'}`}>
                        {msg.message}
                      </p>
                    </div>
                    {/* Uncomment the following line to show timestamps */}
                    {/* <span className="text-xs text-gray-500 leading-none">{msg.timestamp}</span> */}
                  </div>
                  {msg.user_whosend !== 'Wally' && (
                    <span className="relative flex shrink-0 rounded-full justify-center items-center self-start pt-3">
                      <div className="avtar text-base font-bold text-white pl-2">{msg.user_whosend}</div>
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center pt-0 mt-5">
              <form className="flex items-center justify-center w-full space-x-2">
                <input
                  className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                  placeholder="Type your message"
                  value={newMessage}
                  onChange={handleInputChange}
                />
                <button
                  className="inline-flex items-center justify-center rounded-md text-sm font-bold text-white disabled:pointer-events-none disabled:opacity-50 bg-[#0177b5] hover:bg-[#111827E6] hover:text-white h-10 px-4 py-2"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </form>
            </div>
          </>
        </div>
      )}
    </>
  )
}
