import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IOrder } from 'src/_models/order.model'
import ChatAuction from 'src/components/chat/chat-auction'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useUserStore } from 'src/_store/user.store'
import { Skeleton } from 'src/components/ui/skeleton'
import { toast } from 'src/components/ui/use-toast'

const ChatOrderActionBtn = ({ order }: { order: IOrder }) => {
  const [cometChatGroupId, setCometChatGroupId] = useState<string>()
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false)
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const getChatOrderQuery = useQuery({
    queryKey: [`getChatOrder-${order.id}`],
    queryFn: async () => {
      const token = localStorage.getItem('token')
      const resp = await axios.post<{ cometChatGroupId: string }>(
        `/api/comet-chat/getGroupChat/${order.id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      )

      return resp.data
    },
  })

  useEffect(() => {
    if (getChatOrderQuery.data) {
      setCometChatGroupId(getChatOrderQuery.data.cometChatGroupId)
    }
  }, [getChatOrderQuery.data])

  // const getUserChat = async () => {
  //   try {
  //     const resp = await getChatOrderMutation.mutateAsync({ orderId: order.id })
  //     setCometChatGroupId(resp.cometChatGroupId)
  //   } catch (e) {
  //     console.log('Error in getting chat group', e)
  //     toast({
  //       title: 'Error in getting chat group',
  //       description: 'Please try again',
  //     })
  //   }
  // }

  return (
    <>
      {getChatOrderQuery.isLoading ? (
        <Skeleton className="w-[143px] h-[36px] rounded-[5px]" />
      ) : (
        <a
          onClick={() => setIsChatOpen(true)}
          className="shadow-[box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);] flex cursor-pointer items-center gap-[5px] rounded-[5px] bg-primary text-white p-2"
        >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path
                d="M8.33333 0.28125C3.75 0.28125 0 3.26458 0 6.94792C0.0416667 8.73958 0.883333 10.4229 2.29167 11.5312C2.29167 12.0312 1.94167 13.3396 0 15.2812C1.975 15.1896 3.86667 14.4479 5.39167 13.1979C6.34167 13.4729 7.34167 13.6146 8.33333 13.6146C12.9167 13.6146 16.6667 10.6312 16.6667 6.94792C16.6667 3.26458 12.9167 0.28125 8.33333 0.28125ZM8.33333 11.9479C4.65 11.9479 1.66667 9.70625 1.66667 6.94792C1.66667 4.18958 4.65 1.94792 8.33333 1.94792C12.0167 1.94792 15 4.18958 15 6.94792C15 9.70625 12.0167 11.9479 8.33333 11.9479ZM12.5 7.78125V6.11458H10.8333V7.78125H12.5ZM9.16667 7.78125V6.11458H7.5V7.78125H9.16667ZM5.83333 7.78125V6.11458H4.16667V7.78125H5.83333Z"
                fill="#FFFFFF"
              />
            </svg>
          </span>
          <span className="text-sm font-medium 2xl:text-base">
            Chat with {user?.userType === 'BUYER' ? 'Supplier' : 'Buyer'}
          </span>
        </a>
      )}
      {isChatOpen && cometChatGroupId && (
        <ChatAuction order={order} cometChatGroupId={cometChatGroupId} toogleChat={() => setIsChatOpen(false)} />
      )}
    </>
  )
}

export default ChatOrderActionBtn
