import { Stepper } from 'src/components/ui/stepper'
import { Button } from 'src/components/ui/button'
import { t } from 'i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import SupplierOrderDocumentUpload from 'src/components/order/supplier/supplier-order-document-upload'
import SupplierOrderGuaranteeOrder from 'src/components/order/supplier/supplier-order-guarantee-order'
import SupplierOrderBuyerPayment from 'src/components/order/supplier/supplier-order-buyer-payment'
import SupplierOrderShippingDocument from 'src/components/order/supplier/supplier-order-shipping-document'
import SupplierOrderDeliveryConfirmation from 'src/components/order/supplier/supplier-order-delivery-confirmation'
import SupplierOrderCompleted from 'src/components/order/supplier/supplier-order-completed'
import { IBuyerOrder, IOrder, ISupplierOrder } from 'src/_models/order.model'
import { formatAuctionDate, formatFuelType, formatFuelWeightUnit } from 'src/lib/format-auction'
import { formatFuelColor } from 'src/lib/format-auction'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import BuyerOrderCompleted from 'src/components/order/buyer/buyer-order-completed'
import * as React from 'react'
import ChatOrderActionBtn from 'src/components/chat/chat-order-action-btn'

const SupplierOrderDialog = ({
  order,
  setShowOrderStatusModal,
}: {
  order: ISupplierOrder
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  const [orderUpdated, setOrderUpdated] = useState<IOrder>(order)
  const steps = [
    { title: t('supplierOrderSteps.step1') },
    { title: t('supplierOrderSteps.step2') },
    { title: t('supplierOrderSteps.step3') },
    { title: t('supplierOrderSteps.step4') },
    { title: t('supplierOrderSteps.step5') },
    { title: t('supplierOrderSteps.step6') },
  ]
  const [toogleChat, setToogleChat] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)

  const { data } = useQuery({
    queryKey: ['orders', 'orders-' + order.id],
    cacheTime: 60,
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ order: IBuyerOrder }>(`/api/orders/${order.id}?clearActionRequired=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (data) {
      setOrderUpdated(data.data.order)
      if (data.data.order.status === 'DOCUMENT_UPLOAD') {
        setStep(0)
      } else if (
        data.data.order.status === 'BUYER_ORDER_GUARANTEED' ||
        data.data.order.status === 'SELLER_ORDER_GUARANTEED'
      ) {
        setStep(1)
      } else if (data.data.order.status === 'BUYER_PAYMENT_PENDING') {
        setStep(2)
      } else if (
        data.data.order.status === 'SELLER_PARTIAL_PAYMENT' ||
        data.data.order.status === 'SHIPPING_CONTRACT_UPLOAD' ||
        data.data.order.status === 'SHIPPING_PAID'
      ) {
        setStep(3)
      } else if (data.data.order.status === 'PRODUCT_SHIPPED' || data.data.order.status === 'DELIVERY_CONFIRMATION') {
        setStep(4)
      } else if (
        data.data.order.status === 'PRODUCT_CONFIRMATION' ||
        data.data.order.status === 'SELLER_REMAINDER_PAYMENT_PENDING'
      ) {
        setStep(5)
      } else if (data.data.order.status === 'COMPLETED') {
        setStep(6)
      }
    }
  }, [data])

  return (
    <>
      <div className="h-[258px] px-[30px]">
        <div className="text-2xl font-bold text-secoundary">
          <div className="flex items-center justify-between align-middle">
            {orderUpdated.supplierNeedsAttention ? (
              <h2 className="flex items-center gap-[5px] align-middle w-[150px]">
                <span className="">
                  <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M10.8334 7.49935H9.16675V5.83268H10.8334M10.8334 14.166H9.16675V9.16602H10.8334M10.0001 1.66602C8.90573 1.66602 7.8221 1.88156 6.81105 2.30035C5.80001 2.71914 4.88135 3.33297 4.10752 4.10679C2.54472 5.6696 1.66675 7.78921 1.66675 9.99935C1.66675 12.2095 2.54472 14.3291 4.10752 15.8919C4.88135 16.6657 5.80001 17.2796 6.81105 17.6983C7.8221 18.1171 8.90573 18.3327 10.0001 18.3327C12.2102 18.3327 14.3298 17.4547 15.8926 15.8919C17.4554 14.3291 18.3334 12.2095 18.3334 9.99935C18.3334 8.905 18.1179 7.82137 17.6991 6.81032C17.2803 5.79927 16.6665 4.88061 15.8926 4.10679C15.1188 3.33297 14.2002 2.71914 13.1891 2.30035C12.1781 1.88156 11.0944 1.66602 10.0001 1.66602Z"
                        fill="#FF3333"
                      />
                    </svg>
                  </a>
                </span>
                <span className="text-base font-bold text-secoundary 2xl:text-lg">Action Required</span>
              </h2>
            ) : (
              <h2 className="w-[150px]"></h2>
            )}
            <div className="rounded-[9.46px]  px-[10px] py-[5px] text-base font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
              ID# {orderUpdated.auction.generatedId}
            </div>
            <span className="flex gap-2 text-sm font-bold 2xl:text-base">
              {formatAuctionDate(orderUpdated.createdAt)}
            </span>
          </div>
        </div>
        <div className="mt-[10px] rounded-[20px] bg-[#F2F2F2] px-5 py-[23px]">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <p className="2xl-text-xl text-lg font-bold">
                {formatFuelColor(orderUpdated.auction.fuelColor)} {formatFuelType(orderUpdated.auction.fuelType)} P.{' '}
                {orderUpdated.auction.fuelPurityLevel.purity} (
                {t('fuelStateShorthand.' + orderUpdated.auction.fuelState)})
              </p>
              <p className="2xl-text-base flex items-center gap-2 text-sm font-normal">
                by{' '}
                <a href="#" className="underline">
                  {orderUpdated.buyer.companyName}
                </a>
                <ChatOrderActionBtn order={orderUpdated} />
              </p>
            </div>
            <div className="flex flex-col gap-2 rounded-[14.9px] bg-[#E0E0E0] p-[11.83px] text-center">
              <span className="text-xs text-secoundary 2xl:text-sm">
                QTY ({formatFuelWeightUnit(orderUpdated.auction.fuelWeightUnit)})
              </span>
              <span className="text-sm font-bold text-secoundary 2xl:text-base">{orderUpdated.auction.fuelWeight}</span>
            </div>
          </div>
        </div>
        {/* stepper */}
        <div className="customStepper flex w-full flex-col pt-4">
          <Stepper steps={steps} currentStep={step} />
        </div>
      </div>
      {step === 0 && (
        <SupplierOrderDocumentUpload
          goNext={() => setStep(1)}
          setShowOrderStatusModal={setShowOrderStatusModal}
          order={orderUpdated}
        />
      )}
      {step === 1 && (
        <SupplierOrderGuaranteeOrder
          goNext={() => setStep(2)}
          order={orderUpdated}
          setShowOrderStatusModal={setShowOrderStatusModal}
        />
      )}
      {step === 2 && (
        <SupplierOrderBuyerPayment
          goNext={() => setStep(3)}
          order={orderUpdated}
          setShowOrderStatusModal={setShowOrderStatusModal}
        />
      )}
      {step === 3 && (
        <SupplierOrderShippingDocument
          goNext={() => setStep(4)}
          order={orderUpdated}
          setShowOrderStatusModal={setShowOrderStatusModal}
        />
      )}
      {step === 4 && <SupplierOrderDeliveryConfirmation goNext={() => setStep(6)} order={orderUpdated} />}
      {step === 5 && <SupplierOrderCompleted order={orderUpdated} setShowOrderStatusModal={setShowOrderStatusModal} />}
      {step === 6 && <BuyerOrderCompleted order={orderUpdated} />}
    </>
  )
}

export default SupplierOrderDialog
