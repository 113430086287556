import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from 'src/components/ui/dialog'
import { Input } from 'src/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { Button } from 'src/components/ui/button'
import { cn } from 'src/lib/utils'
import { format, getHours, getMinutes, setHours, setMinutes, setSeconds } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { Calendar } from 'src/components/ui/calendar'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { date, z } from 'zod'
import {
  AUCTION_FUEL_STATE,
  AUCTION_TYPE,
  auctionFuelStateEnum,
  auctionTypeEnum,
  CURRENCIES,
  FUEL_WEIGHT_UNIT,
  fuelWeightUnitEnum,
  SHIPPING_MODE,
  SHIPPING_RADIUS_UNIT,
  shippingModeEnum,
  shippingRadiusUnitEnum,
  TRADE_TYPE,
  tradeTypeEnum,
} from 'src/utils/utils'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import { useTranslation } from 'react-i18next'
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group'
import { Label } from 'src/components/ui/label'
import ErrorMessage from 'src/components/ui/error-message'
import { IShippingDetail, IUserAddress } from 'src/_models/shipping-detail.model'
import { IPurityLevel } from 'src/_models/purity-level.model'
import { Checkbox } from 'src/components/ui/checkbox'
import { toast } from 'src/components/ui/use-toast'
import { IInsuranceProvider } from 'src/_models/insurance-provider'
import { InputNumber } from 'antd'
import { Period } from 'src/components/ui/time-picker-utils'
import { TimePickerInput } from 'src/components/ui/time-picker-input'
import { TimePeriodSelect } from 'src/components/ui/time-period-select'
import { IFuelColor, IFuelType } from 'src/_models/order.model'
import CreateAuctionOtherFees from 'src/pages/supplier/fuel-trading/create-auction-other-fees'

export type IAuctionForm = {
  auctionName: string
  auctionType: z.infer<typeof auctionTypeEnum>
  tradeType: z.infer<typeof tradeTypeEnum>
  fuelState: z.infer<typeof auctionFuelStateEnum>
  carbonIntensity: string | null
  fuelTypeId: number
  fuelColorId: number
  fuelPurityLevelId: number
  fuelWeight: number
  fuelWeightUnit: z.infer<typeof fuelWeightUnitEnum>
  sellingPricePerUnit: number
  sellingCurrency: string
  auctionEndDate: Date
  auctionEndTime: Date
  insuranceProviderId: number
  stateTaxPercentage: number
  cityTaxPercentage?: number
  fuelFeesFixed?: number
  fuelFeesPercentage?: number
  auctionOtherFees: {
    label: string
    isPercentage: boolean
    value: number
  }[]
  shippingDetail: {
    name: string
    shippingCurrency: string
    costPerUnitDistance: number
    shippingRadiusUnit: z.infer<typeof shippingRadiusUnitEnum>
    shippingRadius: number
    shippingMode: z.infer<typeof shippingModeEnum>
    userAddressId: number
  }
}

export default function CreateAuction({ className }: { className: string }) {
  const queryClient = useQueryClient()
  const minuteRef = React.useRef<HTMLInputElement>(null)
  const hourRef = React.useRef<HTMLInputElement>(null)
  const periodRef = React.useRef<HTMLButtonElement>(null)
  const [period, setPeriod] = React.useState<Period>('PM')
  const { t } = useTranslation('translation')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [file, setFile] = useState<File>()
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    if (acceptedFiles[0].size > 3145728) {
      toast({
        title: t('File size should be less than 3 MB'),
        variant: 'destructive',
      })
      return
    }
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // maxSize: 1024,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
    },
  })
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  // Validate shipping detail and provider
  const auctionSchema = z
    .object({
      // auctionName: z.string({ required_error: 'error.required' }),
      auctionType: auctionTypeEnum,
      tradeType: tradeTypeEnum,
      fuelState: auctionFuelStateEnum,
      fuelTypeId: z.number({ required_error: 'error.required' }),
      fuelPurityLevelId: z.number({ required_error: 'error.required' }),
      fuelColorId: z.number({ required_error: 'error.required' }),
      fuelWeight: z
        .number({ required_error: 'error.required', invalid_type_error: 'error.invalid' })
        .min(0, 'error.shouldBePositive'),
      fuelWeightUnit: z.enum(FUEL_WEIGHT_UNIT, {
        required_error: 'error.required',
        invalid_type_error: 'error.invalid',
      }),
      sellingPricePerUnit: z
        .number({ required_error: 'error.required', invalid_type_error: 'error.invalid' })
        .min(0, 'error.shouldBePositive'),
      sellingCurrency: z.string({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
      auctionEndDate: z.date({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
      auctionEndTime: z.date({ required_error: 'error.required' }),
      insuranceProviderId: z.number({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
      stateTaxPercentage: z.number({ required_error: 'error.required' }),
      cityTaxPercentage: z.number({ required_error: 'error.required' }).optional(),
      fuelFeesFixed: z.number({ required_error: 'error.required' }).optional(),
      fuelFeesPercentage: z.number({ required_error: 'error.required' }).optional(),
      auctionOtherFees: z.array(
        z.object({
          label: z.string({ required_error: 'error.required' }),
          isPercentage: z.boolean(),
          value: z.number({ required_error: 'error.required' }),
        }),
      ),
      shippingDetail: z.object({
        name: z.string({ required_error: 'error.required' }),
        shippingCurrency: z.string({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
        costPerUnitDistance: z
          .number({ required_error: 'error.required', invalid_type_error: 'error.invalid' })
          .min(0, 'error.shouldBePositive'),
        shippingRadiusUnit: z.enum(SHIPPING_RADIUS_UNIT, {
          required_error: 'error.required',
          invalid_type_error: 'error.invalid',
        }),
        shippingRadius: z.number({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
        shippingMode: z.enum(SHIPPING_MODE, { required_error: 'error.required', invalid_type_error: 'error.invalid' }),
        userAddressId: z.number({ required_error: 'error.required', invalid_type_error: 'error.invalid' }),
      }),
    })
    .refine((data) => data.auctionEndDate > new Date(), {
      message: 'error.auctionEndDate',
      path: ['auctionEndDate'], // path of error
    })

  const { control, handleSubmit, watch, reset, getValues, setValue, setError } = useForm<IAuctionForm>({
    resolver: zodResolver(auctionSchema),
    defaultValues: {
      auctionEndTime: setSeconds(new Date(), 0),
    },
  })

  const getFuelTypes = useQuery({
    queryKey: ['fuelTypes'],
    queryFn: (): Promise<AxiosResponse<{ fuelTypes: IFuelType[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelTypes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: true,
  })

  const getFuelColors = useQuery({
    queryKey: ['fuelColors'],
    queryFn: (): Promise<AxiosResponse<{ fuelColors: IFuelColor[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelColors', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: true,
  })

  const createAuctionMutation = useMutation({
    mutationFn: (data: IAuctionForm): Promise<AxiosResponse<{ auction: IAuction }>> => {
      const token = localStorage.getItem('token')
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        if (key === 'auctionEndDate') {
          // const [hours, minutes] = data.auctionEndTime.split(':')
          const hours = getHours(data.auctionEndTime)
          const minutes = getMinutes(data.auctionEndTime)
          const date = value as Date
          let tempTime = setHours(date, hours)
          tempTime = setMinutes(tempTime, minutes)
          formData.append(key, tempTime.toISOString())
        }
        if (typeof value === 'object') {
          // @ts-ignore
          Object.keys(value).forEach((keyNested) => {
            // @ts-ignore
            if (typeof value[keyNested] === 'object') {
              // @ts-ignore
              Object.keys(value[keyNested]).forEach((keyNestedNested) => {
                // @ts-ignore
                formData.append(`${key}[${keyNested}][${keyNestedNested}]`, value[keyNested][keyNestedNested] || '')
              })
            } else {
              // @ts-ignore
              formData.append(`${key}[${keyNested}]`, value[keyNested] || '')
            }
          })
        } else {
          // @ts-ignore
          formData.append(key, value || '')
        }
      }
      formData.append('file', file || '')
      return axios.post('/api/supplier/auctions', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const purityLevelQuery = useQuery({
    queryKey: [`purityLevel-${getValues().fuelTypeId}`],
    queryFn: (): Promise<AxiosResponse<{ purityLevels: IPurityLevel[] }>> => {
      const fuelType = getValues().fuelTypeId
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelPurityLevels/' + fuelType, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: false,
  })

  const userAddressesQuery = useQuery({
    queryKey: ['user-addresses'],
    queryFn: (): Promise<AxiosResponse<{ userAddresses: IUserAddress[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/user-addresses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const insuranceProvidersQuery = useQuery({
    queryKey: ['insurance-providers'],
    queryFn: (): Promise<AxiosResponse<{ insuranceProviders: IInsuranceProvider[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/supplier/insurance-providers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const fuelColorWatch = watch('fuelColorId')
  const fuelTypeWatch = watch('fuelTypeId')
  const fuelPurityLevelIdWatch = watch('fuelPurityLevelId')

  useEffect(() => {
    if (fuelTypeWatch) {
      purityLevelQuery.refetch()
    }
  }, [fuelTypeWatch])

  useEffect(() => {
    setValue('auctionName', `${getFuelColorLabel(fuelColorWatch) || ''} ${getFuelTypeLabel(fuelTypeWatch) || ''}`)
  }, [fuelColorWatch, fuelTypeWatch, fuelPurityLevelIdWatch])

  const confirmAuction = (auction: IAuctionForm) => {
    setDisabled(true)
  }

  const createAuction = () => {
    if (!acceptTerms) {
      toast({
        title: t('Please accept terms.'),
      })
      return
    }
    const auctionParams = getValues()
    createAuctionMutation.mutate(auctionParams, {
      onSuccess: (resp) => {
        toast({
          title: t('Created auction.'),
        })
        setModalOpened(false)
        queryClient.invalidateQueries()
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string | z.ZodError }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t((error.response?.data.message as string) || error.message || 'error.somethingWrong'),
        })
      },
    })
  }

  const getFuelTypeLabel = (fuelTypeId: number) => {
    return getFuelTypes.data?.data?.fuelTypes.find((fuelType) => fuelType.id === fuelTypeId)?.name
  }

  const getFuelColorLabel = (fuelColorId: number) => {
    return getFuelColors.data?.data?.fuelColors.find((fuelColor) => fuelColor.id === fuelColorId)?.name
  }

  return (
    <Dialog open={modalOpened} onOpenChange={setModalOpened}>
      <DialogTrigger
        asChild
        onClick={() => {
          reset()
          setDisabled(false)
          setAcceptTerms(false)
        }}
      >
        <div className={cn('cursor-pointer  font-montserrat', className)}>
          <div className="flex h-[170px] w-[305px] items-center self-center rounded-3xl border-[5px] border-white bg-[#023047] align-middle 2xl:h-[198px] 2xl:w-[336px]">
            <div className="flex w-full items-center justify-center align-middle">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                <path
                  d="M32.1673 22.1663H22.1673V32.1663H18.834V22.1663H8.83398V18.833H18.834V8.83301H22.1673V18.833H32.1673V22.1663Z"
                  fill="white"
                />
              </svg>
              <p className="pl-[10px] text-xl font-bold text-white">{t('auction.newAuctionTitle')}</p>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="h-5/6 w-full max-w-4xl overflow-y-auto rounded-[40px] p-0">
        <DialogHeader className="px-[30px] pt-[30px]">
          {/*  /!* <DialogTitle className="font-montserrat text-2xl">Create New Auction </DialogTitle> *!/*/}
          {/*  <DialogDescription>*/}
          <div className="text-xl font-bold text-secoundary 2xl:text-2xl">
            {disabled ? t('auction.confirmAuction') : t('auction.newAuction')}
            <hr className="mb-4 mt-5 h-px border-0 bg-[#E0E0E0]" />
          </div>
        </DialogHeader>
        <div className="max-h-[calc(100vh_-_380px)] overflow-auto px-[30px] pr-7">
          <div className="flex w-full gap-[35px]">
            <div
              {...getRootProps()}
              className="flex h-[180px] w-[253px] flex-col items-center justify-center gap-2.5 rounded-lg bg-gray2 text-center"
            >
              <input {...getInputProps()} disabled={disabled} />
              {file ? (
                <img src={URL.createObjectURL(file)} className="max-h-[180px] max-w-[253px]" />
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                    <path
                      d="M6 16C4.48 16 3.18333 15.4767 2.11 14.43C1.03667 13.3767 0.5 12.0933 0.5 10.58C0.5 9.28 0.89 8.12 1.67 7.1C2.45667 6.08 3.48333 5.43 4.75 5.15C5.17 3.61667 6.00333 2.37667 7.25 1.43C8.50333 0.476667 9.92 0 11.5 0C13.4533 0 15.1067 0.68 16.46 2.04C17.82 3.39333 18.5 5.04667 18.5 7C19.6533 7.13333 20.6067 7.63333 21.36 8.5C22.12 9.35333 22.5 10.3533 22.5 11.5C22.5 12.7533 22.0633 13.8167 21.19 14.69C20.3167 15.5633 19.2533 16 18 16H12.5C11.9533 16 11.4833 15.8033 11.09 15.41C10.6967 15.0233 10.5 14.5533 10.5 14V8.85L8.9 10.4L7.5 9L11.5 5L15.5 9L14.1 10.4L12.5 8.85V14H18C18.7 14 19.29 13.7567 19.77 13.27C20.2567 12.79 20.5 12.2 20.5 11.5C20.5 10.8 20.2567 10.21 19.77 9.73C19.29 9.24333 18.7 9 18 9H16.5V7C16.5 5.62 16.0133 4.44 15.04 3.46C14.0667 2.48667 12.8867 2 11.5 2C10.12 2 8.94 2.48667 7.96 3.46C6.98667 4.44 6.5 5.62 6.5 7H6C5.03333 7 4.21 7.34333 3.53 8.03C2.84333 8.71 2.5 9.53333 2.5 10.5C2.5 11.4667 2.84333 12.3 3.53 13C4.21 13.6667 5.03333 14 6 14H8.5V16"
                      fill="#333333"
                    />
                  </svg>
                  <div className="text-base font-bold text-[#828282]">Add Image</div>
                </>
              )}
            </div>
            <div className="w-full">
              <div className="mb-10 flex w-full flex-col">
                <label
                  htmlFor="auctionName"
                  className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  {t('auction.auctionNameLabel')}
                </label>
                <div className="mt-2">
                  <Controller
                    name="auctionName"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <Input
                          disabled={disabled}
                          readOnly={true}
                          id="auctionName"
                          placeholder={t('auction.auctionNameLabel')}
                          {...field}
                          error={fieldState.error}
                        />
                      )
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col">
                <p className="mb-[10px] block text-base font-bold text-secoundary">{t('auction.fuelTypeLabel')}</p>
                <div className="flex flex-col">
                  <Controller
                    control={control}
                    name="fuelTypeId"
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Select
                            disabled={disabled}
                            value={field.value ? field.value.toString() : ''}
                            onValueChange={(e) => field.onChange(parseInt(e))}
                          >
                            <SelectTrigger
                              className={cn(
                                'w-full',
                                disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                              )}
                            >
                              {field.value ? getFuelTypeLabel(field.value) : 'Select Fuel Type'}
                            </SelectTrigger>
                            <SelectContent>
                              {getFuelTypes.data?.data?.fuelTypes.map((fuelType) => {
                                return (
                                  <SelectItem value={fuelType.id.toString()} key={fuelType.id}>
                                    {fuelType.name}
                                  </SelectItem>
                                )
                              })}
                            </SelectContent>
                          </Select>
                          {fieldState.error?.message && (
                            <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                          )}
                        </>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 flex w-full justify-between">
            <div className="w-1/3">
              <p className="mb-[10px] text-sm font-bold 2xl:text-base">{t('auction.fuelStateLabel')}</p>
              <Controller
                control={control}
                name="fuelState"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <RadioGroup
                        disabled={disabled}
                        id="fuelType"
                        name={field.name}
                        value={field.value}
                        onValueChange={field.onChange}
                        className="flex flex-col gap-[20px]"
                        // defaultValue="option-one"
                      >
                        {AUCTION_FUEL_STATE.map((fuelState) => {
                          return (
                            <div key={fuelState} className="flex items-center gap-[10px]">
                              <RadioGroupItem
                                value={fuelState}
                                id={fuelState}
                                // className="h-5 w-5 border-2  border-secoundary bg-white text-primary focus:ring-2 focus:ring-primary"
                              />
                              <Label htmlFor={fuelState} className="text-sm font-normal text-secoundary 2xl:text-base">
                                {t('fuelState.' + fuelState)}
                              </Label>
                            </div>
                          )
                        })}
                      </RadioGroup>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </>
                  )
                }}
              />
            </div>
            <div className="w-1/3">
              <p className="mb-[10px] text-sm font-bold 2xl:text-base">{t('auction.auctionTypeLabel')}</p>
              <Controller
                control={control}
                name="auctionType"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <RadioGroup
                        disabled={disabled}
                        id="fuelType"
                        name={field.name}
                        value={field.value}
                        onValueChange={field.onChange}
                        className="flex flex-col gap-[20px]"
                        // defaultValue="option-one"
                      >
                        {AUCTION_TYPE.map((auctionType) => {
                          return (
                            <div key={auctionType} className="flex items-center gap-[10px]">
                              <RadioGroupItem
                                value={auctionType}
                                id={auctionType}
                                // className="h-5 w-5 border-2  border-secoundary bg-white text-primary focus:ring-2 focus:ring-primary"
                              />
                              <Label
                                htmlFor={auctionType}
                                className="text-sm font-normal text-secoundary 2xl:text-base"
                              >
                                {t('auctionType.' + auctionType)}
                              </Label>
                            </div>
                          )
                        })}
                      </RadioGroup>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </>
                  )
                }}
              />
            </div>
            <div className="w-1/3">
              <p className="mb-[10px] text-base font-bold">{t('auction.tradeTypeLabel')}</p>
              <Controller
                control={control}
                name="tradeType"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <RadioGroup
                        disabled={disabled}
                        id="tradeType"
                        name={field.name}
                        value={field.value}
                        onValueChange={field.onChange}
                        className="flex flex-col gap-[20px]"
                        // defaultValue="option-one"
                      >
                        {TRADE_TYPE.map((tradeType) => {
                          return (
                            <div key={tradeType} className="flex items-center gap-[10px]">
                              <RadioGroupItem
                                value={tradeType}
                                id={tradeType}
                                // className="h-5 w-5 border-2  border-secoundary bg-white text-primary focus:ring-2 focus:ring-primary"
                              />
                              <Label htmlFor={tradeType} className="text-sm font-normal text-secoundary 2xl:text-base">
                                {t('tradeType.' + tradeType)}
                              </Label>
                            </div>
                          )
                        })}
                      </RadioGroup>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </>
                  )
                }}
              />
            </div>
          </div>
          <div className="mt-5 flex w-full justify-between gap-5">
            <div className="flex w-full flex-col">
              <label
                htmlFor="purityLevel"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.purityLevelLabel')}
              </label>
              <Controller
                control={control}
                name="fuelPurityLevelId"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => e && field.onChange(parseInt(e))}
                      >
                        <SelectTrigger
                          className={cn(
                            'w-full',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          <SelectValue placeholder={t('auction.purityLevelLabel')} />
                        </SelectTrigger>
                        <SelectContent>
                          {purityLevelQuery.data?.data?.purityLevels.map((purityLevel) => {
                            return (
                              <SelectItem key={purityLevel.id} value={purityLevel.id.toString()}>
                                {purityLevel.purity}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>

            <div className="flex w-full flex-col">
              <label htmlFor="fuelcolour" className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base">
                {t('auction.fuelColourLabel')}
              </label>
              <Controller
                control={control}
                name="fuelColorId"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => field.onChange(parseInt(e))}
                      >
                        <SelectTrigger
                          className={cn(
                            'h-auto w-full p-4 text-secoundary placeholder-[#CCCCCC] focus:text-secoundary',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          {field.value ? getFuelColorLabel(field.value) : 'Select Fuel Color'}
                        </SelectTrigger>
                        <SelectContent>
                          {getFuelColors.data?.data?.fuelColors.map((fuelColor) => {
                            return (
                              <SelectItem key={fuelColor.id} value={fuelColor.id.toString()}>
                                {fuelColor.name}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>

            <div className="flex w-full flex-col">
              <label htmlFor="weight" className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base">
                {t('auction.fuelWeightLabel')}
              </label>
              <Controller
                control={control}
                name="fuelWeight"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="weight"
                        placeholder={t('auction.fuelWeightLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>

            <div className="flex w-full flex-col">
              <label htmlFor="weightunit" className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base">
                {t('auction.fuelWeightUnitLabel')}
              </label>
              <Controller
                control={control}
                name="fuelWeightUnit"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => field.onChange(e)}
                      >
                        <SelectTrigger
                          className={cn(
                            'h-auto w-full p-4 text-secoundary placeholder-[#CCCCCC] focus:text-secoundary',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          <SelectValue placeholder={t('auction.fuelWeightUnitLabel')} />
                        </SelectTrigger>
                        <SelectContent>
                          {FUEL_WEIGHT_UNIT.map((fuelWeightUnit) => {
                            return (
                              <SelectItem key={fuelWeightUnit} value={fuelWeightUnit}>
                                {t('fuelWeightUnit.' + fuelWeightUnit)}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>
          </div>
          {/* currency */}
          <div className="mt-5 flex justify-between gap-5">
            <div className="flex w-full flex-col">
              <label
                htmlFor="sellingCurrency"
                className="placeholde-[#CCCCCC] block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.sellingCurrencyLabel')}
              </label>
              <Controller
                control={control}
                name="sellingCurrency"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2 flex w-full justify-between">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => field.onChange(e)}
                      >
                        <SelectTrigger
                          className={cn(
                            'h-auto w-full p-4 text-secoundary placeholder-[#CCCCCC] focus:text-secoundary',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          <SelectValue placeholder={t('auction.sellingCurrencyLabel')} />
                        </SelectTrigger>
                        <SelectContent>
                          {CURRENCIES.map((currency) => {
                            return (
                              <SelectItem key={currency} value={currency}>
                                {currency} ({t('currency.' + currency)})
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex w-full flex-col">
              <label
                htmlFor="sellingPricePerUnit"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.sellingPricePerUnitLabel')}
              </label>
              <Controller
                control={control}
                name="sellingPricePerUnit"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="sellingPricePerUnitLabel"
                        placeholder={t('auction.sellingPricePerUnitLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex w-full flex-col">
              <label
                htmlFor="carbonIntensity"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                Carbon Intensity
              </label>
              <Controller
                control={control}
                name="carbonIntensity"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="text"
                        id="carbonIntensityLabel"
                        placeholder="Enter carbon intensity"
                        {...field}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>

          {/* auction end date */}
          <div className="mt-5 flex justify-between gap-10">
            <div className="flex w-full flex-col">
              <label
                htmlFor="auctionEndDate"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.auctionEndDateLabel')}
              </label>
              <Controller
                control={control}
                name="auctionEndDate"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Popover>
                        <PopoverTrigger
                          disabled={disabled}
                          asChild
                          className="text-muted-foreground w-1/2 rounded ring-1 ring-stepcolor"
                        >
                          <Button
                            // variant={'outline'}
                            className={cn(
                              'h-auto w-full justify-between bg-white p-4 text-left font-normal ring-1 ring-stepcolor hover:bg-white focus:bg-white focus:ring-transparent active:bg-white active:ring-transparent',
                              'text-muted-foreground disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                            )}
                          >
                            {field.value ? (
                              <span>{format(field.value, 'PPP')}</span>
                            ) : (
                              <span>{t('auction.auctionEndDateLabel')} </span>
                            )}
                            <CalendarIcon className="mr-2 h-4 w-4" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={(e) => field.onChange(e)}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex w-full flex-col">
              <label
                htmlFor="auctionEndTime"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.auctionEndTimeLabel')}
              </label>
              <Controller
                control={control}
                name="auctionEndTime"
                render={({ field, fieldState }) => {
                  return (
                    <div className="flex flex-row gap-2 mt-2">
                      <TimePickerInput
                        className="w-full"
                        picker="12hours"
                        period={period}
                        date={field.value}
                        disabled={disabled}
                        setDate={(e) => {
                          field.onChange(e)
                        }}
                        ref={hourRef}
                        onRightFocus={() => minuteRef.current?.focus()}
                      />
                      <TimePickerInput
                        className="w-full"
                        picker="minutes"
                        id="minutes12"
                        date={field.value}
                        disabled={disabled}
                        setDate={(e) => {
                          field.onChange(e)
                        }}
                        ref={minuteRef}
                        onLeftFocus={() => hourRef.current?.focus()}
                        onRightFocus={() => periodRef.current?.focus()}
                      />
                      <div className="pt-2">
                        <TimePeriodSelect
                          period={period}
                          setPeriod={setPeriod}
                          date={field.value}
                          setDate={(e) => {
                            field.onChange(e)
                          }}
                          disabled={disabled}
                          ref={periodRef}
                          onLeftFocus={() => minuteRef.current?.focus()}
                        />
                      </div>
                      {/*<Input*/}
                      {/*  className="appearance-none rounded-sm"*/}
                      {/*  disabled={disabled}*/}
                      {/*  type="time"*/}
                      {/*  id="auctionendtime"*/}
                      {/*  autoComplete="auctionendtime"*/}
                      {/*  placeholder={t('auction.auctionEndTimeLabel')}*/}
                      {/*  {...field}*/}
                      {/*  error={fieldState.error}*/}
                      {/*/>*/}
                    </div>
                  )
                }}
              />
            </div>
          </div>
          {/* state and city taxes percentage */}
          <div className="mt-5 flex justify-between gap-10">
            <div className="flex w-full flex-col">
              <label
                htmlFor="stateTaxPercentage"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.stateTaxPercentageLabel')}
              </label>
              <Controller
                control={control}
                name="stateTaxPercentage"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="stateTaxPercentageLabel"
                        placeholder={t('auction.stateTaxPercentageLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex w-full flex-col">
              <label
                htmlFor="cityTaxPercentage"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.cityTaxPercentageLabel')}
              </label>
              <Controller
                control={control}
                name="cityTaxPercentage"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="cityTaxPercentageLabel"
                        placeholder={t('auction.cityTaxPercentageLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className="mt-5 flex justify-between gap-10">
            <div className="flex w-full flex-col">
              <label
                htmlFor="fuelFeesPercentage"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.fuelFeesPercentageLabel')}
              </label>
              <Controller
                control={control}
                name="fuelFeesPercentage"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="fuelFeesPercentageLabel"
                        placeholder={t('auction.fuelFeesPercentageLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex w-full flex-col">
              <label
                htmlFor="fuelFeesFixed"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.fuelFeesFixedLabel')}
              </label>
              <Controller
                control={control}
                name="fuelFeesFixed"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        type="number"
                        id="fuelFeesFixedLabel"
                        placeholder={t('auction.fuelFeesFixedLabel')}
                        {...field}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <CreateAuctionOtherFees control={control} disabled={disabled} />
          {/*shipping address  */}
          <div className="mb-5 mt-10 w-full">
            <h2 className="text-xl font-bold text-secoundary 2xl:text-2xl">Shipping Details</h2>
            <hr className="mb-5 mt-5 h-px border-0 bg-[#E0E0E0]" />

            <div className="mt-5 flex w-full flex-col">
              <label
                htmlFor="shippingProvider.name"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('shippingProvider.nameLabel')}
              </label>
              <Controller
                control={control}
                name="shippingDetail.name"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        disabled={disabled}
                        id="shippingProvider"
                        placeholder={t('shippingProvider.nameLabel')}
                        {...field}
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>

            {/* shipping mode */}
            <div className="mt-5 w-full ">
              <div className="mx-auto flex flex-col justify-center">
                <p className="mb-[10px] block text-base font-bold text-secoundary">
                  {t('shippingProvider.shippingModeLabel')}
                </p>
                <div className="flex flex-col">
                  <Controller
                    control={control}
                    name="shippingDetail.shippingMode"
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <RadioGroup
                            disabled={disabled}
                            id="fuelTypeId"
                            name={field.name}
                            value={field.value}
                            onValueChange={field.onChange}
                            className="flex w-full flex-row gap-10"
                            // defaultValue="option-one"
                          >
                            {SHIPPING_MODE.map((fuelType) => {
                              return (
                                <div key={fuelType} className="flex items-center gap-[10px]">
                                  <RadioGroupItem value={fuelType} id={fuelType} />
                                  <Label htmlFor={fuelType}>{fuelType}</Label>
                                </div>
                              )
                            })}
                          </RadioGroup>
                          {fieldState.error?.message && (
                            <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                          )}
                        </>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            {/* shipping currency */}
            <div className="mt-5 flex w-full justify-between gap-5">
              <div className="flex w-full flex-col">
                <label
                  htmlFor="shippingProvider.shippingCurrency"
                  className="2xl-base block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  {t('shippingProvider.tradeCurrencyLabel')}
                </label>
                <Controller
                  control={control}
                  name="shippingDetail.shippingCurrency"
                  render={({ field, fieldState }) => {
                    return (
                      <div className="mt-2">
                        <Select
                          disabled={disabled}
                          name={field.name}
                          value={field.value ? field.value.toString() : ''}
                          onValueChange={(e) => field.onChange(e)}
                        >
                          <SelectTrigger
                            className={cn(
                              'h-auto w-full p-4 text-secoundary placeholder-[#CCCCCC] focus:text-secoundary',
                              disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                            )}
                          >
                            <SelectValue placeholder={t('auction.sellingCurrencyLabel')} />
                          </SelectTrigger>
                          <SelectContent>
                            {CURRENCIES.map((currency) => {
                              return (
                                <SelectItem key={currency} value={currency}>
                                  {currency}
                                </SelectItem>
                              )
                            })}
                          </SelectContent>
                        </Select>
                        {fieldState.error?.message && (
                          <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                        )}
                      </div>
                    )
                  }}
                />
              </div>

              <div className="flex w-full flex-col">
                <label
                  htmlFor="shippingProvider.costPerUnitDistance"
                  className="2xl-base block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  {t('shippingProvider.shippingCostPerUnitDistanceLabel')}
                </label>
                <Controller
                  control={control}
                  name="shippingDetail.costPerUnitDistance"
                  render={({ field, fieldState }) => {
                    return (
                      <div className="mt-2">
                        <Input
                          disabled={disabled}
                          type="number"
                          id="sellingPricePerUnitLabel"
                          placeholder={t('auction.sellingPricePerUnitLabel')}
                          {...field}
                          onChange={(e) =>
                            e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                          }
                          error={fieldState.error}
                        />
                      </div>
                    )
                  }}
                />
              </div>

              <div className="flex w-full flex-col">
                <label
                  htmlFor="shippingProvider.shippingRadiusUnit"
                  className="2xl-base block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  {t('shippingProvider.distanceUnitLabel')}
                </label>
                <Controller
                  control={control}
                  name="shippingDetail.shippingRadiusUnit"
                  render={({ field, fieldState }) => {
                    return (
                      <div className="mt-2">
                        <Select
                          disabled={disabled}
                          name={field.name}
                          value={field.value ? field.value.toString() : ''}
                          onValueChange={(e) => field.onChange(e)}
                        >
                          <SelectTrigger
                            className={cn(
                              'h-auto w-full p-4 text-secoundary placeholder-[#CCCCCC] focus:text-secoundary',
                              disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                            )}
                          >
                            <SelectValue placeholder={t('shippingProvider.distanceUnitLabel')} />
                          </SelectTrigger>
                          <SelectContent>
                            {SHIPPING_RADIUS_UNIT.map((distanceUnit) => {
                              return (
                                <SelectItem key={distanceUnit} value={distanceUnit}>
                                  {distanceUnit}
                                </SelectItem>
                              )
                            })}
                          </SelectContent>
                        </Select>
                        {fieldState.error?.message && (
                          <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                        )}
                      </div>
                    )
                  }}
                />
              </div>
            </div>
            {/* shippingRadiusUnit */}
            <div className="mt-5 flex w-full flex-col">
              <label
                htmlFor="shippingRadius"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.shippingRadiusLabel')}
              </label>
              <Controller
                control={control}
                name="shippingDetail.shippingRadius"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Input
                        type="number"
                        placeholder={t('auction.shippingRadiusLabel')}
                        {...field}
                        disabled={disabled}
                        onChange={(e) =>
                          e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                        }
                        error={fieldState.error}
                      />
                    </div>
                  )
                }}
              />
            </div>
            {/* insurance provider */}
            <div className="mt-5 flex w-full flex-col">
              <label
                htmlFor="insuranceProviderId"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.insuranceProviderIdLabel')}
              </label>
              <Controller
                control={control}
                name="insuranceProviderId"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => {
                          if (e) field.onChange(parseInt(e))
                        }}
                      >
                        <SelectTrigger
                          className={cn(
                            'w-full',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          <SelectValue placeholder={t('auction.insuranceProviderIdLabel')} />
                        </SelectTrigger>
                        <SelectContent>
                          {insuranceProvidersQuery.data &&
                            insuranceProvidersQuery.data?.data?.insuranceProviders.map((insuranceProvider) => {
                              return (
                                <SelectItem key={insuranceProvider.id} value={insuranceProvider.id.toString()}>
                                  {insuranceProvider.name}
                                </SelectItem>
                              )
                            })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>
            {/* shipping address */}
            <div className="mt-5 flex w-full flex-col">
              <label
                htmlFor="shippingDetail.userAddressId"
                className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
              >
                {t('auction.userAddressIdLabel')}
              </label>
              <Controller
                control={control}
                name="shippingDetail.userAddressId"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <Select
                        disabled={disabled}
                        name={field.name}
                        value={field.value ? field.value.toString() : ''}
                        onValueChange={(e) => {
                          if (e) field.onChange(parseInt(e))
                        }}
                      >
                        <SelectTrigger
                          className={cn(
                            'w-full',
                            disabled && 'disabled:bg-gray2 disabled:opacity-100 disabled:text-black',
                          )}
                        >
                          <SelectValue placeholder={t('auction.userAddressIdLabel')} />
                        </SelectTrigger>
                        <SelectContent>
                          {userAddressesQuery.data?.data?.userAddresses.map((userAddress) => {
                            return (
                              <SelectItem key={userAddress.id} value={userAddress.id.toString()}>
                                {userAddress.streetAddress} {userAddress.streetAddress ? ',' : ''}{' '}
                                {userAddress.googleAutocompleteAddress}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      {fieldState.error?.message && (
                        <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                      )}
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
        {/*  </DialogDescription>*/}
        {/*</DialogHeader>*/}
        <DialogFooter className="px-[30px] pb-[30px]">
          <div className="relative">
            {disabled && (
              <>
                <Label className="mb-5 flex gap-2.5 align-middle">
                  <Checkbox checked={acceptTerms} onCheckedChange={(e) => setAcceptTerms(!!e)} /> I agree that by
                  checking this, i will not be able to edit the auction details
                </Label>
              </>
            )}
            {disabled ? (
              <div className="mt-5 flex gap-5">
                <Button type="button" variant="secondary" onClick={() => setDisabled(false)}>
                  Back
                </Button>
                <Button type="submit" onClick={() => createAuction()}>
                  Confirm
                </Button>
              </div>
            ) : (
              <div className="mt-5 flex gap-5">
                <DialogClose asChild>
                  <Button type="button" variant="secondary">
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  onClick={handleSubmit(confirmAuction, (err) => {
                    toast({
                      variant: 'destructive',
                      title: 'Please check the form and try again.',
                    })
                  })}
                >
                  Start Auction
                </Button>
              </div>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
