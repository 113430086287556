import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger } from 'src/components/ui/select'
import { formatAuctionDate } from 'src/lib/format-auction'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { IRecentUpdate } from 'src/_models/recent-update.model'
import { IPlatformUpdate } from 'src/_models/platform-update.model'
import { useUserStore } from 'src/_store/user.store'
import { useNavigate } from 'react-router-dom'
import DashboardSharedPlatformUpdates from 'src/pages/dashboard/dashboard-shared-platform-updates'
import { t } from 'i18next'

const DashboardSharedRecentUpdates = () => {
  const { user } = useUserStore()
  const [selectedUpdate, setSelectedUpdate] = useState<'recentUpdate' | 'platformUpdate'>('platformUpdate')
  const recentUpdatesQuery = useQuery({
    queryKey: ['recentUpdates'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/recent-updates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ recentUpdates: IRecentUpdate[] }>>
    },
    cacheTime: 60,
    enabled: selectedUpdate === 'recentUpdate',
  })

  // const platformUpdatesQuery = useQuery({
  //   queryKey: ['platformUpdates'],
  //   queryFn: () => {
  //     const token = localStorage.getItem('token')
  //     return axios.get('/api/platform-updates', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }) as Promise<AxiosResponse<{ platformUpdates: IPlatformUpdate[]; }>>
  //     {
  //       data: {
  //         platformUpdates: []
  //       }
  //     }
  //   },
  //   cacheTime: 60,
  //   enabled: selectedUpdate === 'platformUpdate',
  // })

  return (
    <div className="shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] mt-5 rounded-[20px] border-2 border-white bg-[#e9f9ff] pt-[20px] 2xl:pt-[30px]">
      <div className="flex w-full items-center justify-center px-[20px] align-middle 2xl:px-[30px] gap-2 rounded-[10px]">
        <Select value={selectedUpdate} onValueChange={(e: 'recentUpdate' | 'platformUpdate') => setSelectedUpdate(e)}>
          <div className="text-white bg-[#023047] rounded-[7px] w-full">
            <SelectTrigger>
              <div className="flex-1 justify-center w-full">
                {selectedUpdate === 'recentUpdate' ? 'Recent Updates' : 'Platform Updates'}
              </div>
            </SelectTrigger>
          </div>
          <SelectContent className="bg-[#023047] text-white ">
            <SelectItem value="platformUpdate">Platform Updates</SelectItem>
            <SelectItem value="recentUpdate">Recent Updates</SelectItem>
          </SelectContent>
        </Select>

        {/*<div className="2lx:text-xl text-sm font-bold text-secoundary">Recent Updates</div>*/}
        <div className="text-xs font-medium text-secoundary 2xl:text-sm">
          <Dialog>
            <DialogTrigger asChild>
              <a className="cursor-pointer">View&nbsp;all</a>
            </DialogTrigger>
            <DialogContent className="h-5/6 overflow-y-auto rounded-[40px]">
              <div className="flex flex-col w-full h-full overflow-y-scroll">
                {/*{*/}
                {/*  selectedUpdate === 'platformUpdate' &&*/}
                {/*  platformUpdatesQuery.data &&*/}
                {/*  platformUpdatesQuery.data.data &&*/}
                {/*  <PlatformUpdateCard platformUpdates={platformUpdatesQuery.data.data.platformUpdates} />*/}
                {/*}*/}
                {selectedUpdate === 'recentUpdate' && recentUpdatesQuery.data && recentUpdatesQuery.data.data && (
                  <RecentUpdateCard recentUpdates={recentUpdatesQuery.data.data.recentUpdates} />
                )}
                {selectedUpdate === 'platformUpdate' && user?.userType && (
                  <DashboardSharedPlatformUpdates type={user?.userType} />
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <div className="my-5 flex w-full flex-col overflow-y-auto px-[20px] xl:max-h-[415px] 2xl:max-h-[502px] 2xl:px-[30px]">
        {selectedUpdate === 'platformUpdate' && user?.userType && (
          <DashboardSharedPlatformUpdates type={user?.userType} />
        )}
        {selectedUpdate === 'recentUpdate' && recentUpdatesQuery.data && recentUpdatesQuery.data.data && (
          <RecentUpdateCard recentUpdates={recentUpdatesQuery.data.data.recentUpdates} />
        )}
      </div>
    </div>
  )
}

const PlatformUpdateCard = ({ platformUpdates }: { platformUpdates: IPlatformUpdate[] }) => {
  if (platformUpdates.length === 0) return <div className="text-center">No updates</div>

  return platformUpdates?.splice(0, 5)?.map((platformUpdate) => (
    <div key={platformUpdate.id}>
      <div className="flex w-full justify-between gap-10 ">
        <div className="w-auto">
          <div className="flex w-full flex-col">
            <div className="flex items-center">
              <span className="text-xs font-bold 2xl:text-base">Green Ammonia</span>
              <span className="ml-[5px] inline-block rounded-sm bg-white px-[4px] py-[3px] text-xs font-bold text-primary 2xl:text-base">
                99.9995%
              </span>
            </div>
            <div className="text-xs font-medium 2xl:text-sm">{platformUpdate.message}</div>
            <div className="w-full text-xs font-normal text-[#828282] 2xl:text-sm">
              {formatAuctionDate(platformUpdate.createdAt)}
            </div>
          </div>
        </div>
        {/*<div className="w-8 text-center align-middle">*/}
        {/*  <div className="w-full text-xs font-normal text-[#828282] 2xl:text-sm">{formatAuctionDate(platformUpdate.createdAt)}</div>*/}
        {/*  <div className="mx-auto flex h-2 w-2 justify-center rounded-full bg-primary text-center align-middle"></div>*/}
        {/*</div>*/}
      </div>
      <hr className="border-1 my-2.5 h-px bg-[#E0E0E0]" />
    </div>
  ))
}

const RecentUpdateCard = ({ recentUpdates }: { recentUpdates: IRecentUpdate[] }) => {
  if (recentUpdates.length === 0) return <div className="text-center">No updates</div>

  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const navigate = useNavigate()

  const goToRecentUpdate = (recentUpdate: IRecentUpdate) => {
    if (user?.userType === 'BUYER') {
      navigate(`/buyer/fuel-trading?recent-update=${recentUpdate.auction.generatedId}`)
    } else {
      navigate(`/supplier/fuel-trading?recent-update=${recentUpdate.auction.generatedId}`)
    }
  }

  return recentUpdates.map((recentUpdate) => (
    <div key={recentUpdate.id} className="cursor-pointer" onClick={() => goToRecentUpdate(recentUpdate)}>
      <div className="flex w-full justify-between gap-10 ">
        <div className="w-auto">
          <div className="flex w-full flex-col">
            <div className="flex items-center">
              <span className="text-xs font-bold text-primary 2xl:text-base">
                ID# {recentUpdate.auction.generatedId}
              </span>
            </div>
            <div className="text-xs font-medium 2xl:text-sm">
              <span className="inline-block rounded-sm pr-[4px] py-[3px] text-xs font-bold 2xl:text-base">
                {recentUpdate.auction.fuelColor.name} {recentUpdate.auction.fuelType.name}{' '}
                {recentUpdate.auction.fuelPurityLevel.purity} (
                {t('fuelStateShorthand.' + recentUpdate.auction.fuelState)})
              </span>{' '}
              {recentUpdate.message}
            </div>
            <div className="w-full text-xs font-normal text-[#828282] 2xl:text-sm">
              {formatAuctionDate(recentUpdate.createdAt)}
            </div>
          </div>
        </div>
      </div>
      <hr className="border-1 my-2.5 h-px bg-[#E0E0E0]" />
    </div>
  ))
}

export default DashboardSharedRecentUpdates
