import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IAuction, IAuctionSupplier, IBidSupplier } from 'src/_models/auction.model'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { differenceInDays, format } from 'date-fns'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import { ColumnDef } from '@tanstack/react-table'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { DataTable } from 'src/components/data-table'
import * as React from 'react'
import { useUserStore } from 'src/_store/user.store'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import { cn } from 'src/lib/utils'
import { formatAuctionDate } from 'src/lib/format-auction'
import AuctionRating from 'src/components/auction/auction-rating'
import AuctionDialogHeader from 'src/components/auction/auction-dialog-header'
import AuctionDialogEstimatedShippingAndShippingRadius from 'src/components/auction/auction-dialog-estimated-shipping-and-shipping-radius'
import { isPastAuction } from 'src/lib/auction.util'
import AuctionDialogFeesCalculation from 'src/components/auction/auction-dialog-fees-calculation'
import AuctionDialogTopHeader from 'src/components/auction/auction-dialog-top-header'

const SupplierAuctionDialogAuction = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation('translation')
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })
  const [total, setTotal] = useState(0)
  const [confirmClose, setConfirmClose] = useState<boolean>()
  const [confirmEndAuction, setConfirmEndAuction] = useState<boolean>()
  const [highestBid, setHighestBid] = useState<IBidSupplier | null>(null)
  const queryClient = useQueryClient()
  const [auctionData, setAuctionData] = useState<IAuctionSupplier | null>(null)
  const {
    isLoading,
    data: responseData,
    error,
  } = useQuery({
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuctionSupplier }>(`/api/supplier/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (responseData && responseData.data) {
      setHighestBid(null)
      // Setting auction data
      setAuctionData(responseData.data.auction)
      // Getting the highest bid
      let highestBid: IBidSupplier | undefined
      responseData.data.auction.bids
      for (const bid of responseData.data.auction.bids) {
        if (!highestBid?.bidAmount || highestBid?.bidAmount < bid.bidAmount) highestBid = bid
      }
      if (highestBid) setHighestBid(highestBid)
    }
  }, [responseData])

  const cancelAuctionMutation = useMutation({
    mutationFn: (): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/auctions/${auction.id}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
    onSuccess: (resp) => {
      toast({
        title: t(resp.data.message),
      })
      setShowDialog(false)
      queryClient.invalidateQueries({
        queryKey: ['supplier-my-auctions'],
      })
    },
    onError: (err) => {
      const error = err as AxiosError<{ message: string }>
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: t(error.response?.data?.message || 'error.somethingWrong'),
      })
    },
  })

  const endAuctionMutation = useMutation({
    mutationFn: (): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/auctions/${auction.id}/end`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
    onSuccess: (resp) => {
      toast({
        title: t(resp.data.message),
      })
      queryClient.invalidateQueries()
      setShowDialog(false)
    },
    onError: (err) => {
      const error = err as AxiosError<{ message: string }>
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: t(error.response?.data?.message || 'error.somethingWrong'),
      })
    },
  })

  if (isLoading || error || !auctionData) {
    return
  }

  const days = differenceInDays(auctionData.auctionEndDate, new Date())

  return (
    <>
      <div className="mx-auto h-full w-full max-w-4xl overflow-y-auto rounded-[40px] bg-white px-[30px] py-[30px]">
        <div className="text-2xl font-bold text-secoundary">
          <AuctionDialogHeader auction={auction} />
          <hr className="my-5 h-px border-0 bg-[#E0E0E0]" />
        </div>
        <div className="max-h-[calc(100vh_-_380px)] overflow-auto">
          <AuctionDialogTopHeader auction={auctionData} />
          <div className="my-5 flex w-full flex-col">
            <div className="flex justify-around divide-x-[1px] divide-verticalline align-middle">
              <div className="flex w-full flex-col items-center justify-center gap-[10px] py-2">
                <p className="block text-sm text-secoundary 2xl:text-base ">Quantity</p>
                <p className="block text-lg font-bold text-secoundary 2xl:text-xl">
                  {auctionData.fuelWeight} {t('fuelWeightUnit.' + auctionData.fuelWeightUnit)}
                </p>
              </div>
              <div className="flex w-full flex-col items-center justify-center gap-[10px] py-2">
                <p className="block text-sm text-secoundary 2xl:text-base">
                  {auction.bids.length > 0 ? 'Highest Bid' : 'Floor Price'}
                </p>
                <p className="block text-lg font-bold text-secoundary 2xl:text-xl">
                  <CurrencyFormatter currency={auction.sellingCurrency} amount={highestBid?.bidAmount} cents={true} />
                </p>
              </div>
              <div className="flex w-full flex-col items-center justify-center gap-[10px] py-2">
                <SupplierViewAllAuctions bids={auctionData.bids} currency={auction.sellingCurrency} />
                <p className="block text-lg font-bold text-secoundary 2xl:text-xl">{auctionData.bids.length}</p>
              </div>
            </div>
          </div>
          {highestBid && (
            <>
              <div className="flex w-full flex-col rounded-lg pb-5">
                <div className="flex flex-col justify-around">
                  <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col">
                      <p className=" block flex  flex-col pl-[30px] text-sm text-secoundary 2xl:text-base">
                        <span>Highest Bid:</span>
                        <span className="text-sm font-bold text-secoundary 2xl:text-base">Base Value:</span>
                      </p>
                      <p className="block pl-[30px] text-sm font-bold  text-secoundary 2xl:text-base">
                        {highestBid?.bidUserName}
                      </p>
                    </div>
                    <p className=" block text-sm font-bold text-secoundary 2xl:text-base">
                      {/*<Input*/}
                      {/*  id="puritylevel"*/}
                      {/*  autoComplete="Purity Level"*/}
                      {/*  placeholder="example"*/}
                      {/*  name="puritylevel"*/}
                      {/*  value={formatAmountFromCents(highestBid?.bidAmount)}*/}
                      {/*  spellCheck="false"*/}
                      {/*  data-ms-editor="false"*/}
                      {/*  className="w-[200px] bg-[#F2F2F2] p-3.5 pr-7 text-right"*/}
                      {/*  disabled*/}
                      {/*/>*/}
                      <div className="border:none w-[200px] rounded-[10px] bg-[#F2F2F2] py-4  pr-[30px] text-right ring-0">
                        <CurrencyFormatter
                          currency={auction.sellingCurrency}
                          amount={highestBid?.bidAmount}
                          cents={true}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col rounded-lg pb-5">
                <div className="flex flex-col px-[30px]">
                  <AuctionDialogFeesCalculation
                    auction={auction}
                    bidAmount={highestBid.bidAmount}
                    setTotal={setTotal}
                    cents={true}
                  />
                  <AuctionDialogEstimatedShippingAndShippingRadius auction={auction} />
                </div>
              </div>
            </>
          )}
        </div>
        <hr className=" h-px border-0 bg-[#E0E0E0]" />
        {highestBid && (
          <>
            <div className="flex w-full flex-col rounded-lg pt-5">
              <div className="flex flex-col px-[30px]">
                <div className="flex w-full items-center justify-between gap-[10px]">
                  <p className=" block text-lg font-bold text-secoundary 2xl:text-xl">Total Payable Amount</p>
                  <p className=" block text-lg font-bold text-secoundary 2xl:text-xl">
                    {/*{formatAmount(highestBid?.totalAmount)}*/}
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={highestBid?.totalAmount}
                      cents={true}
                    />
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Only show the buttons if the auction is not past auction */}
        {!isPastAuction(auctionData) && (
          <>
            {confirmClose && (
              <div className="mt-[30px] px-[30px] pb-5 text-sm font-normal 2xl:text-base">
                *Are you sure, you want to Cancel auction{' '}
                <span className="uppercase">
                  {auctionData.fuelColor.name} {auctionData.fuelType.name}
                </span>{' '}
                {auctionData.fuelPurityLevel.purity}
                {/*<span className="font-bold">*/}
                {/*  <CurrencyFormatter currency={auction.sellingCurrency} amount={highestBid?.totalAmount} cents={true} />*/}
                {/*</span>*/}
              </div>
            )}

            {confirmEndAuction && (
              <div className="mt-[30px] px-[30px] pb-5 text-sm font-normal 2xl:text-base">
                *Are you sure, you want to end auction{' '}
                <span className="uppercase">
                  {auctionData.fuelColor.name} {auctionData.fuelType.name}
                </span>{' '}
                {auctionData.fuelPurityLevel.purity}.{/*<span className="font-bold">*/}
                {/*  <CurrencyFormatter currency={auction.sellingCurrency} amount={highestBid?.totalAmount} cents={true} />*/}
                {/*</span>*/}
              </div>
            )}

            {auction.auctionStatus === 'LIVE' && (
              <>
                {confirmClose && (
                  <div className="mt-5 flex gap-5">
                    <Button
                      variant="secondary"
                      disabled={cancelAuctionMutation.isLoading}
                      onClick={() => {
                        setConfirmClose(false)
                      }}
                      className="py-3.5 text-base"
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="default"
                      disabled={cancelAuctionMutation.isLoading}
                      onClick={() => {
                        cancelAuctionMutation.mutate()
                      }}
                      className="py-3.5 text-base"
                    >
                      Confirm Cancel Auction
                    </Button>
                  </div>
                )}
                {confirmEndAuction && (
                  <div className="mt-5 flex gap-5">
                    <Button
                      variant="secondary"
                      disabled={endAuctionMutation.isLoading}
                      onClick={() => {
                        setConfirmEndAuction(false)
                      }}
                      className="py-3.5 text-base"
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="default"
                      disabled={endAuctionMutation.isLoading}
                      onClick={() => {
                        endAuctionMutation.mutate()
                      }}
                      className="py-3.5 text-base"
                    >
                      Confirm End Auction
                    </Button>
                  </div>
                )}
                {!confirmEndAuction && !confirmClose && user?.id === auction.userId && (
                  <div className="mt-5 flex gap-5">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setConfirmClose(true)
                      }}
                      className="py-3.5 text-base"
                    >
                      Cancel Auction
                    </Button>
                    <Button
                      onClick={() => {
                        setConfirmEndAuction(true)
                      }}
                      className="py-3.5 text-base"
                    >
                      End Now
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

const SupplierViewAllAuctions = ({ bids, currency }: { bids: IBidSupplier[]; currency: string }) => {
  const columns: ColumnDef<IBidSupplier>[] = [
    {
      accessorKey: 'bidDate',
      header: 'Date & time',
      cell: ({ row }) => {
        return <div>{formatAuctionDate(row.renderValue('bidDate'))}</div>
      },
    },
    {
      accessorKey: 'bidName',
      header: 'Bidder Name',
    },
    {
      accessorKey: 'bidAmount',
      header: 'Bid Amount',
      cell: ({ row }) => {
        return (
          <div>
            <CurrencyFormatter currency={currency} amount={row.renderValue('bidAmount')} cents={true} />
          </div>
        )
      },
    },
  ]

  return (
    <Dialog>
      <DialogTrigger className=" gap-1 text-sm text-primary  2xl:text-base">
        <div className="flex justify-items-center">
          <span className="underline">Total Bids</span>&nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            className="relative"
          >
            <path
              d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"
              fill="#0077B6"
            />
          </svg>
        </div>
      </DialogTrigger>
      <DialogContent className="mx-auto min-h-[380px] w-[650px] overflow-y-auto rounded-[20px] bg-[#f2f2f2] p-0">
        <DataTable variant="column-bg-grey" columns={columns} data={bids} emptyLabel="No Bidders yet" />
      </DialogContent>
    </Dialog>
  )
}

export default SupplierAuctionDialogAuction
