import { IAuction } from 'src/_models/auction.model'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'
import AuctionRating from 'src/components/auction/auction-rating'

const AuctionDialogTopHeader = ({ auction }: { auction: IAuction }) => {
  const { t } = useTranslation('translation')
  return (
    <>
      <div className="flex w-full items-center gap-5 align-middle">
        <div className="flex h-[124px] w-[155px] flex-col items-center justify-center gap-2.5 rounded-[10px] bg-[#E0E0E0] text-center">
          {auction.media?.fileUrl && <img src={auction.media?.fileUrl} className="h-full w-full object-cover" />}
        </div>
        <div className="">
          <div className="flex w-full flex-col gap-2">
            <div className="inline-flex items-center gap-[11.83px] align-middle">
              <label
                htmlFor="action"
                className="text-base font-bold leading-[19.3px] text-secoundary 2xl:text-xl 2xl:leading-[24.3px] uppercase"
              >
                {auction.fuelColor.name} {auction.fuelType.name}
              </label>
              <div className="rounded-[9.46px] bg-[rgb(242,242,242)] px-[10px] py-[5px] text-sm font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
                {auction.fuelPurityLevel.purity}{' '}
              </div>
              <div className="rounded-[9.4px] bg-[#F2994A] px-[10px] py-[5px] text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-[19.5px]">
                {auction.tradeType}
              </div>
              <div className="rounded-[9.4px] bg-black px-[10px] py-[5px] text-sm font-bold uppercase leading-[17px] text-white 2xl:text-base 2xl:leading-[19.5px]">
                {t('fuelStateShorthand.' + auction.fuelState)}
              </div>
            </div>
            {auction.carbonIntensity && auction.carbonIntensity.length > 0 && (
              <p className="text-[15px] font-bold 2xl:text-base mb-4">Carbon Intensity: {auction.carbonIntensity}</p>
            )}
            <p className="text-sm font-normal 2xl:text-base">by {auction.user.companyName}</p>
            <p className="flex gap-2 text-sm font-normal 2xl:text-base">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 0 17 23" fill="none">
                <rect width="17" height="23" fill="" />
                <g id="Live Purchase now Hover">
                  <rect x="-258" y="-183" width="719" height="630" rx="34" fill="white" />
                  <rect x="-258" y="-183" width="719" height="630" rx="34" stroke="white" strokeWidth="12" />
                  <g id="Frame 5">
                    <g id="Frame 1000002615">
                      <g id="Frame 1000002612">
                        <g id="Frame 33">
                          <g id="Frame 1000002620">
                            <path
                              id="Vector"
                              d="M8.38095 2.59524C10.6857 2.59524 12.5714 4.48095 12.5714 6.78571C12.5714 8.98571 10.3714 12.5476 8.38095 15.0619C6.39048 12.4429 4.19048 8.98571 4.19048 6.78571C4.19048 4.48095 6.07619 2.59524 8.38095 2.59524ZM8.38095 0.5C4.92381 0.5 2.09524 3.32857 2.09524 6.78571C2.09524 11.5 8.38095 18.3095 8.38095 18.3095C8.38095 18.3095 14.6667 11.3952 14.6667 6.78571C14.6667 3.32857 11.8381 0.5 8.38095 0.5ZM8.38095 4.69048C7.22857 4.69048 6.28571 5.63333 6.28571 6.78571C6.28571 7.9381 7.22857 8.88095 8.38095 8.88095C9.53333 8.88095 10.4762 7.9381 10.4762 6.78571C10.4762 5.63333 9.53333 4.69048 8.38095 4.69048ZM16.7619 18.3095C16.7619 20.6143 12.9905 22.5 8.38095 22.5C3.77143 22.5 0 20.6143 0 18.3095C0 16.9476 1.25714 15.7952 3.24762 14.9571L3.87619 15.9C2.82857 16.4238 2.09524 17.0524 2.09524 17.7857C2.09524 19.2524 4.92381 20.4048 8.38095 20.4048C11.8381 20.4048 14.6667 19.2524 14.6667 17.7857C14.6667 17.0524 13.9333 16.4238 12.781 15.9L13.4095 14.9571C15.5048 15.7952 16.7619 16.9476 16.7619 18.3095Z"
                              fill="#333333"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              {`${auction.shippingDetail.userAddress.city ? auction.shippingDetail.userAddress.city + ', ' : ''}${
                auction.shippingDetail.userAddress.state
              }`}
            </p>

            {/*<div className="rating">*/}
            {/*  <div className="flex items-center">*/}
            {/*    {auction.rating && <AuctionRating rating={parseFloat(auction.rating)} />}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default AuctionDialogTopHeader
