import { z } from 'zod'

export const CURRENCIES = ['USD', 'CAD', 'EUR', 'GBP', 'SGD', 'MYR', 'AUD', 'SAR', 'INR', 'AED'] as const
export const SHIPPING_MODE = ['SEA', 'LAND'] as const
export const SHIPPING_RADIUS_UNIT = ['KM', 'MILE'] as const
export const AUCTION_TYPE = ['AUCTION', 'PURCHASE_NOW'] as const
export const AUCTION_FUEL_STATE = ['GAS', 'LIQUID'] as const
export const TRADE_TYPE = ['SPOT', 'FUTURES'] as const
export const FUEL_TYPE = ['HYDROGEN', 'AMMONIA', 'METHANOL'] as const
export const FUEL_COLOR = ['YELLOW', 'GREEN', 'GREY', 'BLUE'] as const
export const FUEL_WEIGHT_UNIT = ['KG', 'TONNE'] as const

export const AUCTION_STATUS = ['LIVE', 'CANCELLED', 'ENDED'] as const

export const shippingModeEnum = z.enum(SHIPPING_MODE)
export const shippingRadiusUnitEnum = z.enum(SHIPPING_RADIUS_UNIT)
export const currenciesEnum = z.enum(CURRENCIES)
export const auctionTypeEnum = z.enum(AUCTION_TYPE)
export const auctionFuelStateEnum = z.enum(AUCTION_FUEL_STATE)
export const tradeTypeEnum = z.enum(TRADE_TYPE)
export const fuelTypeEnum = z.enum(FUEL_TYPE)
export const fuelColorEnum = z.enum(FUEL_COLOR)
export const fuelWeightUnitEnum = z.enum(FUEL_WEIGHT_UNIT)
export const auctionStatusEnum = z.enum(AUCTION_STATUS)
